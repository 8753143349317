/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { get } from 'lodash';
import { Button, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { useNavigate } from 'react-router-dom';
import { StyleSheet, css } from 'aphrodite/no-important';
import { graphql } from 'react-apollo';
import mutateLogout from './graphql/mutateLogout';
import withRoles from '../hoc/withRoles';
import { TeamListItemAvatar } from './team/TeamListItem';

const sx = StyleSheet.create({
  dropdownIcon: {
    marginLeft: '5px',
    marginRight: '5px',
    transform: 'translateY(5px)',
    alignItems: 'inherit',
  },
  userIcon: {
    height: '27px !important',
    width: '27px !important',
    marginRight: '5px',
    padding: '3px',
    transform: 'translateY(-5px)',
  },
  settingsDropdownContainer: {
    padding: '0.5rem !important',
    display: 'flex',
    transform: 'translateY(4px)',
  },
  btnPadding: {
    padding: '0px !important',
    display: 'flex',
    alignItems: 'center',
  },
});

const getSettingsItems = (currentUser, isSystemAdmin, isPbsTeam, hasRole, handlers) => {
  const callCenterFeatureFlag = get(currentUser, 'feature_flags.callCenter');
  const items = [
    {
      key: '1',
      label: <div onClick={() => handlers.handleClickAccount()}>My Account</div>,
    },
  ];
  if (currentUser && !isSystemAdmin) {
    items.push({
      key: '2',
      label: <div onClick={() => handlers.handleClickTeam()}>My Team</div>,
    });
  }
  if (currentUser && !callCenterFeatureFlag && (isPbsTeam || isSystemAdmin)) {
    items.push({
      key: '9',
      label: <div onClick={() => handlers.handleClickPhones()}>Phones</div>,
    });
  }
  items.push(
    {
      key: '12',
      label: <div onClick={() => handlers.handleClickHelp()}>Help</div>,
    },
    {
      key: '13',
      label: <div onClick={() => handlers.handleLogout()}>Sign Out</div>,
    },
  );

  return items;
};

const SettingsTopBarDropdown = ({
  isSystemAdmin,
  isPbsTeam,
  hasRole,
  currentUser,
  currentUserName,
}) => {
  const navigate = useNavigate();
  const handlers = {
    handleClickAccount: () => navigate('/settings/account'),
    handleClickHelp: () => navigate('/settings/help'),
    handleClickPhones: () => navigate('/settings/phones'),
    handleClickTeam: () => navigate('/settings/team'),
    handleLogout: () => {
      navigate('/signin');
    },
  };

  const items = getSettingsItems(currentUser, isSystemAdmin, isPbsTeam, hasRole, handlers);

  return (
    <Button type="text" className={css(sx.btnPadding)}>
      <Dropdown menu={{ items }} trigger={['click']}>
        <div className={css(sx.settingsDropdownContainer)}>
          {<TeamListItemAvatar user={currentUser} className={css(sx.userIcon)} />}
          {`${currentUserName}`}
          {<DownOutlined className={css(sx.dropdownIcon)} />}
        </div>
      </Dropdown>
    </Button>
  );
};

SettingsTopBarDropdown.propTypes = {
  currentUser: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    feature_flags: PropTypes.object,
  }),
  currentUserName: PropTypes.string,
  hasRole: PropTypes.object,
  isSystemAdmin: PropTypes.bool,
  isPbsTeam: PropTypes.bool,
};

export default compose(graphql(mutateLogout, { name: 'mutateLogout' }))(withRoles(SettingsTopBarDropdown));