import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Device } from 'twilio-client';
import { message, Button, Typography } from 'antd';
import { useSubscription } from '@apollo/react-hooks';
import _get from 'lodash/get';
import { Formik, Form, Field } from 'formik';
import { StyleSheet, css } from 'aphrodite/no-important';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import { CALL_CENTER_USER_STATUS_SUBSCRIPTION } from './queries';

const sx = StyleSheet.create({
  callCenterStatus: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '30px',
    height: '50px',
  },
  statusText: {
    marginBottom: '3px',
    fontSize: '10px',
    color: 'black',
  },
  flexAlignedCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  onCallStatus: {
    border: '1px solid #767676',
    borderRadius: '2.5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 12px',
    fontSize: '14px',
  },
  linkText: {
    color: '#3A3BC9',
    fontSize: '12px',
    paddingTop: '10px',
  },
});

const { Text } = Typography;

const CallCenterStatus = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    deregisterTwilioRefContext,
    callCenterUserStatusContext,
    setCallCenterUserStatusContext,
    updateCallCenterStatusMutationContext,
    isCallCenterActivatedContext,
    supervisingCallContext,
  } = useContext(CallCenterContext);

  const { data: userStatusSubData } = useSubscription(CALL_CENTER_USER_STATUS_SUBSCRIPTION, {
    onError: error => {
      message.error(`Error in user status subscription: ${error.message}`);
    },
  });

  useEffect(() => {
    // Checks if other apps activated itself and fired an event to deregister this app
    if (
      _get(userStatusSubData, 'getCallCenterUserStatusSubscription.deregisterTwilio') &&
      isCallCenterActivatedContext &&
      !deregisterTwilioRefContext.current
    ) {
      Device.destroy();
      deregisterTwilioRefContext.current = false;
    }

    // Updates the current call center status from the api subscription
    const updatedCallCenterStatus = _get(
      userStatusSubData,
      'getCallCenterUserStatusSubscription.callCenterUserStatus',
    );
    if (updatedCallCenterStatus && callCenterUserStatusContext !== updatedCallCenterStatus) {
      setCallCenterUserStatusContext(updatedCallCenterStatus);
    }
  }, [userStatusSubData]);

  if (!isCallCenterActivatedContext) {
    return null;
  }

  const returnToMembersProfile = () => {
    navigate('/members');
  };

  function renderStatus() {
    if (supervisingCallContext) {
      return <div className={css(sx.onCallStatus)}>🔴 Supervising</div>;
    } else if (callCenterUserStatusContext === 'oncall') {
      return <div className={css(sx.onCallStatus)}>🔴 On Call</div>;
    } else if (callCenterUserStatusContext === 'postcall') {
      return <div className={css(sx.onCallStatus)}>🔴 Post Call</div>;
    }

    return (
      <Field
        name="status"
        as="select"
        value={callCenterUserStatusContext}
        onChange={e => {
          updateCallCenterStatusMutationContext(_get(e, 'target.value'));
        }}
      >
        <option value="available" label="Available" />
        <option value="away" label="Away" />
      </Field>
    );
  }

  return (
    <div className={css(sx.callCenterStatus)}>
      <div>
        {location?.pathname?.includes('/settings/pharmacylist/modify') && (
          <Button type="link" onClick={returnToMembersProfile}>
            <Text className={css(sx.linkText)} underline>
              Return to Member Profile
            </Text>
          </Button>
        )}
      </div>
      <Formik initialValues={{ callCenterUserStatusContext }}>
        {() => (
          <Form className={css(sx.flexAlignedCenter)}>
            <div className={css(sx.statusText)}>Call Status</div>
            {renderStatus()}
          </Form>
        )}
      </Formik>
    </div>
  );
};

CallCenterStatus.propTypes = {
  location: PropTypes.any,
  history: PropTypes.object,
};

export default CallCenterStatus;