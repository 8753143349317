import React, { useEffect, useState, useContext, useReducer } from 'react';
import { Device } from 'twilio-client';
import { message, Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import _get from 'lodash/get';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import { StyleSheet, css } from 'aphrodite/no-important';

import { COLORS } from './constants';
import { SessionContext } from '../../contexts/SessionContextProvider';
import OnCall from './OnCall';
import { GET_ACTIVE_CONFERENCE, ACTIVE_CONFERENCE_SUBSCRIPTION } from './queries';
import { HangupIcon } from './svg';
import commonSx, { getBtnColor } from './styles';

const sx = StyleSheet.create({
  callCenterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '6rem',
  },
  callCenterBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '40px',
    margin: '10px',
    lineHeight: '14px',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  dialCont: {
    display: 'flex',
    height: '60px',
  },
  pharmacyBtn: {
    marginLeft: '10px',
    borderRadius: '6px',
    border: '1px solid green',
    cursor: 'pointer',
    backgroundColor: 'white',
    color: COLORS.GREEN,
    fontWeight: 'bold',
    ':hover': {
      background: COLORS.LIGHT_GREEN,
    },
  },
  loadingPatient: {
    display: 'flex',
    alignItems: 'center',
    height: '60px',
  },
});

function OnCallSupervising({ callId, onHangup }) {
  const navigate = useNavigate();
  const { userId } = useContext(SessionContext);

  const currentClient = `client:${userId}`;
  // TODO: Need to update the date to the actual start
  const [onCallStartTime, setOnCallStartTime] = useState();
  const [participants, dispatch] = useReducer(parReducer, {});

  function parReducer(state, action) {
    switch (action.type) {
      case 'INIT':
        return {
          ...action.participants,
        };
      case 'ADD':
        return {
          ...state,
          [action.participant.callSid]: {
            ...action.participant,
          },
        };
      case 'REMOVE':
        delete state[action.participant.callSid];
        return {
          ...state,
        };

      case 'UPDATE':
        return {
          ...state,
          [action.participant.callSid]: {
            ...state[action.participant.callSid],
            hold: action.participant.hold,
            muted: action.participant.muted,
          },
        };
      default:
        throw new Error('No action found');
    }
  }

  // ================================ API call ======================================= //
  const { data: getActiveConfData } = useQuery(GET_ACTIVE_CONFERENCE, {
    fetchPolicy: 'network-only',
    variables: {
      callId: parseInt(callId, 10),
    },
    onError: error => {
      message.error(`Error when fetching getActiveConference: ${error.message}`);
    },
    onCompleted: data => {
      if (_get(data, 'getActiveConference')) {
        const participants = _get(data, 'getActiveConference.data.participants');
        const parObj = {};

        participants
          .filter(par => !par.coaching)
          .forEach(par => {
            parObj[par.callSid] = par;
          });

        dispatch({ type: 'INIT', participants: parObj });

        if (!onCallStartTime) {
          setOnCallStartTime(new Date(_get(data, 'getActiveConference.data.conferenceCreatedAt')));
        }
      }
    },
  });

  // =============================== useSubscription ======================================= //
  const { data: activeConfSub } = useSubscription(ACTIVE_CONFERENCE_SUBSCRIPTION, {
    variables: { conferenceSid: _get(getActiveConfData, 'getActiveConference.data.conferenceSid') },
    skip: !_get(getActiveConfData, 'getActiveConference.data.conferenceSid'),
    shouldResubscribe: true, // should be set to true when conference id changes
    fetchPolicy: 'network-only',
    onError: error => {
      message.error(`Error in active conference subscription: ${error.message}`);
    },
  });

  // =============================== useEffect ======================================= //

  // Initial state and clean up
  useEffect(() => {
    Device.on('disconnect', onHangup);

    // Clean up when unmounting
    return () => {
      Device.removeListener('disconnect', onHangup);
    };
  }, []);

  useEffect(() => {
    if (_get(activeConfSub, 'getActiveConferenceSubscription.action')) {
      const action = _get(activeConfSub, 'getActiveConferenceSubscription.action');
      const participant = _get(activeConfSub, 'getActiveConferenceSubscription.participants')[0];

      if (!_get(participant, 'coaching')) {
        dispatch({ type: action, participant });
      }
    }
  }, [activeConfSub]);

  function getParNames() {
    return Object.keys(participants).map(callSid => participants[callSid].name);
  }

  function getParTypes() {
    return Object.keys(participants).map(callSid => participants[callSid].type);
  }

  function getParNumbers() {
    return Object.keys(participants).map(callSid => participants[callSid].number);
  }

  function getCallSids() {
    return Object.keys(participants);
  }

  return (
    <>
      <div className={css(sx.callCenterBar)}>
        <div className={css(sx.flexRow)}>
          <OnCall
            onCallStartTime={onCallStartTime}
            currentClient={currentClient}
            names={getParNames()}
            onCallTypes={getParTypes()}
            callSids={getCallSids()}
            phones={getParNumbers()}
            isSupervising
          />
          <Tooltip placement="bottom" title="Leave">
            <Button
              className={css(getBtnColor('red'), commonSx.square, commonSx.ml15)}
              onClick={() => {
                // Remove self from conference.
                // Make API call to remove supervisor from conference
                onHangup();
              }}
            >
              <HangupIcon color="white" />
            </Button>
          </Tooltip>
        </div>
      </div>
    </>
  );
}

OnCallSupervising.propTypes = {
  callId: PropTypes.number.isRequired,
  onHangup: PropTypes.func,
};

export default OnCallSupervising;