import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'react-apollo';
import { reduxForm } from 'redux-form';
import { users } from '@zipdrug/react-api-sdk';
import { alertOnError, emailField, validateForm } from '@zipdrug/ui';
import ForgotPasswordForm from './ForgotPasswordForm';
import { useNavigate } from 'react-router-dom';

const ERROR_MESSAGES = {
  invalidEmail: 'is invalid',
  required: 'is required.',
};

const FORGOT_PASSWORD_SCHEMA = {
  email: {
    ...emailField,
    required: true,
  },
};

const validate = values => validateForm(values, FORGOT_PASSWORD_SCHEMA, ERROR_MESSAGES);

const ReduxForgotPasswordForm = reduxForm({
  form: 'forgotPassword',
  validate,
})(ForgotPasswordForm);

const ForgotPasswordFormContainer = ({ requestPasswordReset }) => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => navigate('/signin');

  const handleSubmit = async ({ email }) =>
    alertOnError(
      requestPasswordReset({
        variables: { email },
      }),
    );

  return (
    <ReduxForgotPasswordForm
      initialValues={{ email: null }}
      onBackButtonClick={handleBackButtonClick}
      onSubmit={handleSubmit}
    />
  );
};

ForgotPasswordFormContainer.propTypes = {
  requestPasswordReset: PropTypes.func.isRequired,
};

export default graphql(users.mutateRequestPasswordReset, { name: 'requestPasswordReset' })(
  ForgotPasswordFormContainer,
);