import React, { useRef, useEffect, useState, useContext, useReducer } from 'react';
import _isEmpty from 'lodash/isEmpty';
import { Device } from 'twilio-client';
import { message, Button, Tooltip, Modal, Typography } from 'antd';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import { useQuery, useSubscription, useMutation, useApolloClient } from '@apollo/react-hooks';
import { StyleSheet, css } from 'aphrodite/no-important';

import { COLORS } from './constants';
import { SessionContext } from '../../contexts/SessionContextProvider';
import { CallCenterContext } from '../../contexts/CallCenterContextProvider';
import DialPad from './DialPad';
import AddEnrollerButton from './AddEnrollerButton';
import OnCall from './OnCall';
import SwitchPatient from './SwitchPatient';
import {
  GET_ACTIVE_CONFERENCE,
  UPDATE_PARTICIPANT,
  ADD_PARTICIPANT,
  UPDATE_CALL_RECORD,
  END_CALL,
  ACTIVE_CONFERENCE_SUBSCRIPTION,
  GET_PHONES_WITH_ASSOCIATIONS,
} from './queries';
import { getAvailableEnrollersQuery, getCallRecordQuery } from './util';
import { HangupIcon, MuteIcon, UnmuteIcon, AddUserIcon } from './svg';
import commonSx, { getBtnColor } from './styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';


const sx = StyleSheet.create({
  callCenterContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '6rem',
  },
  callCenterBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '40px',
    margin: '10px',
    lineHeight: '14px',
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  dialCont: {
    display: 'flex',
    height: '60px',
  },
  pharmacyBtn: {
    marginLeft: '10px',
    borderRadius: '6px',
    border: '1px solid green',
    cursor: 'pointer',
    backgroundColor: 'white',
    color: COLORS.GREEN,
    fontWeight: 'bold',
    ':hover': {
      background: COLORS.LIGHT_GREEN,
    },
  },
  disabled: {
    color: '#00000040',
    cursor: 'not-allowed',
    background: '#f5f5f5',
    borderColor: '#d9d9d9',
    textShadow: 'none',
    boxShadow: 'none',
    ':hover': {
      background: '#f5f5f5',
      borderColor: '#d9d9d9',
      color: '#00000040',
    },
  },
  loadingPatient: {
    display: 'flex',
    alignItems: 'center',
    height: '60px',
  },
  bold: {
    fontWeight: 'bold',
  },
  pb50: {
    paddingBottom: '50px',
  },
});

const { Title } = Typography;
function OnCallContainer({
  callId,
  isCallIdSet,
  refreshActiveConfRef,
  refreshActiveConf,
  onClickUnknownNumber,
  onSwitchPatient,
  onCallDialingParticipant,
  onCallUpdatingParticipants,
  setOnCallDialingParticipant,
  endConference,
  twilioConnection,
  selectedPatientData,
  isSaveCallEnabled,
}) {
  const { userId } = useContext(SessionContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const {
    setSelectedPatientId,
    outboundToPhoneIdContext,
    setOutboundToPhoneIdContext,
  } = useContext(CallCenterContext);

  const currentClient = `client:${userId}`;
  const [participants, dispatch] = useReducer(parReducer, {});
  const [conferenceSid, setConferenceSid] = useState(null);
  const [onCallStartTime, setOnCallStartTime] = useState();
  const [isMuted, setIsMuted] = useState(false);
  const [availableEnrollers, setAvailableEnrollers] = useState({});
  const [patientHasMultAssoc, setPatientHasMultAssoc] = useState(false);
  const [disableHoldBtn, setDisableHoldBtn] = useState(false);
  const [isConfirmTransferModalOpen, setIsConfirmTransferModalOpen] = useState(false);
  const [currentEnrollerBeingAdded, setCurrentEnrollerBeingAdded] = useState({});
  const [isTransferring, setIsTransferring] = useState(false);
  const [currentParticipants, setCurrentParticipants] = useState([]);

  const unknownNumberRef = useRef(null);
  const getActiveConfSubInitRef = useRef(true);
  const apolloClient = useApolloClient();

  // ================================ API call ======================================= //
  const {
    data: getActiveConfData,
    refetch: refetchActiveConference,
    startPolling: startPollGetActiveConf,
    stopPolling: stopPollGetActiveConf,
  } = useQuery(GET_ACTIVE_CONFERENCE, {
    fetchPolicy: 'network-only',
    variables: {
      callId: parseInt(callId, 10),
    },
    onError: error => {
      message.error(`Error when fetching getActiveConference: ${error.message}`);
    },
  });

  const [updateCallRecordMutation] = useMutation(UPDATE_CALL_RECORD, {
    onError: error => {
      message.error(`Error when updating call record: ${error.message}`);
    },
  });

  const [updateCallRecordAfterTransfer] = useMutation(UPDATE_CALL_RECORD, {
    onError: () => {
      message.error('Error when updating call record after transferring call');
      setIsTransferring(false);
    },
    onCompleted: () => {
      setIsTransferring(false);
      setIsConfirmTransferModalOpen(false);
    },
  });

  const [endCallMutation] = useMutation(END_CALL, {
    onError: error => {
      message.error(`Error when ending call: ${error.message}`);
    },
  });

  const [addParticipant] = useMutation(ADD_PARTICIPANT, {
    onError: error => {
      message.error(`Error when adding participant: ${error.message}`);
    },
  });

  const [updateParticipant] = useMutation(UPDATE_PARTICIPANT, {
    onError: error => {
      message.error(`Error when updating participant: ${error.meessage}`);
    },
  });

  // =============================== useSubscription ======================================= //
  const { data: activeConfSub } = useSubscription(ACTIVE_CONFERENCE_SUBSCRIPTION, {
    variables: { conferenceSid: _get(getActiveConfData, 'getActiveConference.data.conferenceSid') },
    skip: !_get(getActiveConfData, 'getActiveConference.data.conferenceSid'),
    shouldResubscribe: true, // should be set to true when conference id changes
    fetchPolicy: 'network-only',
    onError: error => {
      message.error(`Error in active conference subscription: ${error.message}`);
    },
  });

  function parReducer(state, action) {
    switch (action.type) {
      case 'INIT':
        return {
          ...action.participants,
        };
      case 'ADD':
        return {
          ...state,
          [action.participant.callSid]: {
            ...action.participant,
          },
        };
      case 'REMOVE':
        delete state[action.participant.callSid];
        return {
          ...state,
        };

      case 'UPDATE':
        return {
          ...state,
          [action.participant.callSid]: {
            ...state[action.participant.callSid],
            hold: action.participant.hold,
            muted: action.participant.muted,
          },
        };
      default:
        return state;
    }
  }

  function getPatientName(patientParticipant, callParticipant) {
    const patientName = patientParticipant?.name;
    if (
      callParticipant?.participantType === 'patient' && callParticipant?.names?.length && !callParticipant.names.includes(patientName)
    ) {
      return callParticipant.names[0];
    } else if (callParticipant?.names?.length && callParticipant?.numbers?.length && callParticipant?.numbers[0] === patientParticipant.number && patientParticipant.name === 'Unknown Caller') {
      return callParticipant.names[0];
    }
    return patientName;
  }

  useEffect(() => {
    if (_get(activeConfSub, 'getActiveConferenceSubscription.action')) {
      const action = _get(activeConfSub, 'getActiveConferenceSubscription.action');
      const participants = _get(activeConfSub, 'getActiveConferenceSubscription.participants');
      const participant = participants[0];
      onCallUpdatingParticipants(participants);
      // expose participants to CallCenterBar to determine end call modals
      if (action === 'ADD') {
        setCurrentParticipants(participants);
        // open the transfer confirmation modal when call gets connected to enroller
        if (
          participant?.type === 'care_manager' &&
          currentEnrollerBeingAdded?.name &&
          participant?.name === currentEnrollerBeingAdded?.name[0]
        ) {
          setIsConfirmTransferModalOpen(true);
          setOnCallDialingParticipant(null);
        }
      }

      // We refetch the conference to get the most updated participants on the first subscription event
      // because there's a small chance that we missed a subscription event while initializing the subscription
      if (getActiveConfSubInitRef.current) {
        refetchActiveConference();
        getActiveConfSubInitRef.current = false;
      }

      const participantName = getPatientName(participant, onCallDialingParticipant);
      participant.name = participantName;

      if (!_get(participant, 'coaching')) {
        dispatch({ type: action, participant });
      }

      if (action === 'ADD' || action === 'REMOVE') {
        const callSid = participant?.callSid;
        const number = participant?.number;

        if (
          _get(onCallDialingParticipant, 'callSids', []).includes(callSid) ||
          _get(onCallDialingParticipant, 'numbers', []).includes(number) ||
          _get(participant, 'type') === 'pharmacy'
        ) {
          setOnCallDialingParticipant(null);
        }
      }

      if (_get(activeConfSub, 'getActiveConferenceSubscription.action') === 'REMOVE_DIALING_CALL') {
        setOnCallDialingParticipant(null);
      }
    }
  }, [activeConfSub]);

  useEffect(() => {
    if (refreshActiveConf && refetchActiveConference) {
      refetchActiveConference();
      refreshActiveConfRef.current = false; // eslint-disable-line
    }
  }, [refreshActiveConf]);

  // Initial state and clean up
  useEffect(() => {
    setOnCallStartTime(Date.now());

    function twilioDiscHandler() {
      if (unknownNumberRef.current) {
        endConference(unknownNumberRef.current, currentParticipants);
      } else {
        endConference(null, currentParticipants);
      }
    }

    Device.on('disconnect', twilioDiscHandler);

    // Clean up when unmounting
    return () => {
      Device.removeListener('disconnect', twilioDiscHandler);
    };
  }, []);

  useEffect(() => {
    unknownNumberRef.current = getUnknownNumberOnCall();
  }, [participants]);

  useEffect(() => {
    if (callId) {
      startPollGetActiveConf(1000);
    } else {
      stopPollGetActiveConf();
    }
  }, [callId]);

  useEffect(() => {
    if (getActiveConfData && _get(getActiveConfData, 'getActiveConference')) {
      // reconcile onCall states with the actual conference
      const participants = _get(getActiveConfData, 'getActiveConference.data.participants');
      const conferenceSid = _get(getActiveConfData, 'getActiveConference.data.conferenceSid');

      const parObj = {};

      participants
        .filter(par => !par.coaching)
        .forEach(par => {
          parObj[par.callSid] = par;

          if (par.hasMultipleAssociations) {
            setPatientHasMultAssoc(true);
          }
        });

      dispatch({ type: 'INIT', participants: parObj });
      setCurrentParticipants(participants);
      onCallUpdatingParticipants(participants);

      // eslint-disable-next-line
      FS.log('log', `conferenceSid: ${conferenceSid}`);
      setConferenceSid(conferenceSid);

      stopPollGetActiveConf();
    }
  }, [getActiveConfData]);

  // This useEffect is triggered by components outside the call center bar. i.e. pressing call button in the members profile
  useEffect(() => {
    async function addParticipantInConference(phoneId) {
      let phoneRecords = await apolloClient.query({
        query: GET_PHONES_WITH_ASSOCIATIONS,
        variables: { query: { id: phoneId } },
        fetchPolicy: 'network-only',
      });
      phoneRecords = _get(phoneRecords, 'data.phones.data');

      let participantType;
      let name;
      let number;
      if (phoneRecords[0] && _get(phoneRecords[0], 'pharmacy.id')) {
        participantType = 'pharmacy';
        name = _get(phoneRecords[0], 'pharmacy.name');
      } else if (phoneRecords[0] && _get(phoneRecords[0], 'prescriber.id')) {
        participantType = 'prescriber';
        name = _get(phoneRecords[0], 'prescriber.name');
        number = _get(phoneRecords[0], 'number');
      } else if (phoneRecords[0] && _get(phoneRecords[0], 'plan.id')) {
        participantType = 'payer_hotline';
        name = _get(phoneRecords[0], 'plan.name');
        number = _get(phoneRecords[0], 'number');
      } else if (phoneRecords[0] && _get(phoneRecords[0], 'patient.id')) {
        participantType = 'patient';
        name = `${_get(phoneRecords[0], 'patient.first_name')} ${_get(
          phoneRecords[0],
          'patient.last_name',
        )}`;
        number = _get(phoneRecords[0], 'number');
      }

      // special case for CPCC numbers
      const label = _get(phoneRecords[0], 'label');
      if (name === null && number === null && label.includes('CPCC')) {
        name = label;
        number = _get(phoneRecords[0], 'number');
      }

      // Do not allow to add pharmacy if they're already in the call
      if (participantType === 'pharmacy' && getParTypes().includes('pharmacy')) {
        message.warning("You're already on a call with a pharmacist");
        return;
      }

      const activeConf = _get(getActiveConfData, 'getActiveConference.data');
      const variables = {
        conferenceSid: activeConf?.conferenceSid,
        participantType,
      };

      if (participantType === 'pharmacy') {
        variables.pharmacyId = _get(phoneRecords[0], 'pharmacy.id');
      } else {
        variables.toPhoneId = phoneId.toString();
        variables.phoneNumber = number;
      }

      const r = await addParticipant({
        variables,
      });
      const callSids = _get(r, 'data.addParticipant');

      setOnCallDialingParticipant({
        names: [name],
        numbers: [number],
        callSids,
        participantType,
      });
    }

    // Add a participant only if your not dialing someone already.
    if (outboundToPhoneIdContext) {
      if (_isEmpty(onCallDialingParticipant)) {
        addParticipantInConference(outboundToPhoneIdContext);
      } else {
        message.warning("You can't add a participant while dialing someone on the call");
      }

      setOutboundToPhoneIdContext(null);
    }
  }, [isCallIdSet]);

  const dialpadEntryHandler = key => {
    twilioConnection.sendDigits(key);
  };

  const getUnknownNumberOnCall = () => {
    const callSid = Object.keys(participants).find(callSid => {
      if (participants[callSid].name === 'Unknown Caller') {
        return true;
      }

      return false;
    });

    return participants[callSid] && participants[callSid].number;
  };

  async function onCallHangup(callSid) {
    const par = participants[callSid];
    const { number } = par;

    // Reset the call center state if participant is less than 2 or if current client hangs up.
    if (Object.keys(participants).length <= 2 || number === currentClient) {
      if (getUnknownNumberOnCall()) {
        endConference(unknownNumberRef?.current, currentParticipants);
      } else {
        endConference(null, currentParticipants);
      }

      return;
    }

    if (number) {
      await updateParticipant({
        variables: {
          conferenceId: conferenceSid,
          participantSid: callSid,
          action: {
            status: 'complete',
          },
        },
      });
    }

    setDisableHoldBtn(false);
  }

  async function updateOnCallModifiers(callSid, modifier) {
    const participant = participants[callSid];
    const activeConf = _get(getActiveConfData, 'getActiveConference.data');

    if (participant) {
      await updateParticipant({
        variables: {
          conferenceId: activeConf.conferenceSid,
          participantSid: participant.callSid,
          action: {
            modifier,
          },
        },
      });
    }

    setDisableHoldBtn(false);
  }

  async function cancelDialingName() {
    // Cancelling dialing participant. Only pharmacy fan out call or enroller have a callSid
    const callSids = _get(onCallDialingParticipant, 'callSids');
    if (callSids) {
      await Promise.all(
        callSids.map(async callSid => {
          return endCallMutation({
            variables: {
              callSid,
            },
          });
        }),
      );
    } else if (twilioConnection) {
      twilioConnection.disconnect();
    }

    setOnCallDialingParticipant(null);
  }

  const showAddPharmacyBtn = () => {
    const parTypes = getParTypes();
    const pharmacyOnCall = parTypes.includes('pharmacy');

    const isPatientPartOfConference = parTypes.includes('patient');
    const isPharmacyOnDialing =
      _get(onCallDialingParticipant, 'participantType') &&
      _get(onCallDialingParticipant, 'participantType').includes('pharmacy');

    const pharmacyNameExists = _get(selectedPatientData, 'pharmacyInfo.name');
    const isDialingSomeone = !_isEmpty(onCallDialingParticipant);

    return (
      !pharmacyOnCall &&
      !isDialingSomeone &&
      isPatientPartOfConference &&
      selectedPatientData &&
      !isPharmacyOnDialing &&
      pharmacyNameExists
    );
  };

  const showAddEnrollerBtn = () => {
    return _isEmpty(onCallDialingParticipant);
  };

  const getPatientNumberFromConference = () => {
    const callSid = Object.keys(participants).find(callSid => {
      if (participants[callSid]?.type === 'patient') {
        return true;
      }

      return false;
    });

    if (callSid) {
      return participants[callSid].number;
    }

    return '';
  };

  const onMembersPage = _get(location, 'pathname').match('members');

  function getParNames() {
    return Object.keys(participants).map(callSid => participants[callSid]?.name);
  }

  function getParTypes() {
    return Object.keys(participants).map(callSid => participants[callSid]?.type);
  }

  function getParNumbers() {
    return Object.keys(participants).map(callSid => participants[callSid]?.number);
  }

  function getParHoldModifiers() {
    return Object.keys(participants).map(callSid => participants[callSid]?.hold);
  }

  function getCallSids() {
    return Object.keys(participants);
  }

  function renderOnCall() {
    return (
      <>
        <div className={css(sx.callCenterBar)}>
          <div className={css(sx.flexRow)}>
            <OnCall
              onCallStartTime={onCallStartTime}
              currentClient={currentClient}
              names={getParNames()}
              onCallTypes={getParTypes()}
              phones={getParNumbers()}
              holdModifiers={getParHoldModifiers()}
              callSids={getCallSids()}
              dialingNames={_get(onCallDialingParticipant, 'names')}
              dialingNumbers={_get(onCallDialingParticipant, 'numbers')}
              cancelDialingName={cancelDialingName}
              onHangup={callSid => {
                if (!disableHoldBtn) {
                  setDisableHoldBtn(true);
                  onCallHangup(callSid);
                }
              }}
              onHold={callSid => {
                if (!disableHoldBtn) {
                  setDisableHoldBtn(true);
                  updateOnCallModifiers(callSid, { hold: !participants[callSid].hold });
                }
              }}
            />
            {/* Add pharmacy button */}
            {showAddPharmacyBtn() && (
              <button
                disabled={selectedPatientData?.pharmacyInfo?.status !== 'active'}
                className={
                  selectedPatientData?.pharmacyInfo?.status === 'active'
                    ? css(sx.pharmacyBtn, commonSx.ml10)
                    : css(sx.disabled)
                }
                onClick={async () => {
                  // add participant to confenrence
                  const activeConf = _get(getActiveConfData, 'getActiveConference.data');
                  const pharmacyId = _get(selectedPatientData, 'pharmacyInfo.pharmacyId');
                  const r = await addParticipant({
                    variables: {
                      conferenceSid: activeConf?.conferenceSid,
                      pharmacyId,
                      participantType: 'pharmacy',
                    },
                  });

                  const callSids = _get(r, 'data.addParticipant');

                  setOnCallDialingParticipant({
                    names: [_get(selectedPatientData, 'pharmacyInfo.name')],
                    participantType: ['pharmacy'],
                    callSids,
                  });
                }}
              >
                {selectedPatientData?.pharmacyInfo?.name}
              </button>
            )}
            {/* Add enroller button */}
            {showAddEnrollerBtn() && (
              <AddEnrollerButton
                callBtnHandler={async index => {
                  // add participant to conference
                  const activeConf = _get(getActiveConfData, 'getActiveConference.data');
                  try {
                    const r = await addParticipant({
                      variables: {
                        conferenceSid: activeConf?.conferenceSid,
                        userId: availableEnrollers?.ids[index],
                        participantType: 'care_manager',
                      },
                    });
                    if (r) {
                      setCurrentEnrollerBeingAdded({
                        name: [availableEnrollers?.names[index]],
                        userId: availableEnrollers?.ids[index],
                      });
                    }

                    const callSid = _get(r, 'data.addParticipant')[0];

                    setOnCallDialingParticipant({
                      names: [availableEnrollers?.names[index]],
                      numbers: [`client:${availableEnrollers?.ids[index]}`],
                      callSids: [callSid],
                    });
                  } catch (e) {
                    message.error(`Error when adding participant: ${e.message}`);
                  }
                }}
                onClick={async () => {
                  setAvailableEnrollers({});
                  // Fetch list of available enrollers on button click so we get the most up to date list
                  const enrollers = await getAvailableEnrollersQuery(apolloClient, userId);
                  setAvailableEnrollers({
                    names: enrollers?.names,
                    ids: enrollers?.ids,
                    labels: enrollers?.labels,
                    phones: enrollers?.phones,
                    primaryIndex: enrollers?.primaryIndex,
                  });
                }}
                names={availableEnrollers?.names}
              />
            )}
            {/* save unknown call to member */}
            {onMembersPage && getUnknownNumberOnCall() && (
              <Button
                type="purple"
                className={css([sx.bold, commonSx.ml10])}
                onClick={() => {
                  onClickUnknownNumber(getUnknownNumberOnCall(), true);
                }}
                disabled={!isSaveCallEnabled}
              >
                {`Save call to ${_get(selectedPatientData, 'patientName')}`}
              </Button>
            )}
            {/* save unknown call and number to member */}
            {onMembersPage && getUnknownNumberOnCall() && (
              <Button
                type="purple"
                className={css([sx.bold, commonSx.ml10])}
                onClick={() => {
                  onClickUnknownNumber(getUnknownNumberOnCall(), false);
                }}
                disabled={!isSaveCallEnabled}
              >
                {`Save call and number to ${_get(selectedPatientData, 'patientName')}`}
              </Button>
            )}
            {/* switch patient */}
            {patientHasMultAssoc && !_isEmpty(getPatientNumberFromConference()) && (
              <SwitchPatient
                participants={participants}
                patientNumber={getPatientNumberFromConference()}
                onChangeSelectedPatient={async (newPatientId, newPhoneId) => {
                  setSelectedPatientId(newPatientId);
                  onSwitchPatient(newPatientId);
                  const callRecord = await getCallRecordQuery(apolloClient, parseInt(callId, 10));
                  const callDirection = _get(callRecord, 'direction');
                  const isCallInbound = callDirection === 'inbound';

                  const variables = {
                    call_id: parseInt(callId, 10),
                  };

                  // TODO: Need to update this and use the direction in the Call record
                  // The call was an inbound call
                  if (isCallInbound && _get(callRecord, 'from_phone_id')) {
                    variables.from_phone_id = newPhoneId;
                  }
                  // The call is an outbound call
                  else if (!isCallInbound && _get(callRecord, 'to_phone_id')) {
                    variables.to_phone_id = newPhoneId;
                  }

                  await updateCallRecordMutation({
                    variables,
                  });

                  refetchActiveConference();
                }}
              />
            )}
            {getParNames().length > 0 && (
              <DialPad dialpadEntryHandler={dialpadEntryHandler} isOnCall />
            )}
            {getParNames().length > 0 && (
              <Tooltip placement="bottom" title={isMuted ? 'Unmute' : 'Mute'}>
                <Button
                  type="primary"
                  className={css(commonSx.square, commonSx.ml10)}
                  onClick={() => {
                    setIsMuted(!isMuted);

                    const callSid = Object.keys(participants).find(
                      callSid => participants[callSid].number === currentClient,
                    );
                    updateOnCallModifiers(callSid, { muted: !isMuted });
                  }}
                >
                  {isMuted ? <UnmuteIcon /> : <MuteIcon />}
                </Button>
              </Tooltip>
            )}
            {twilioConnection && (
              <Tooltip placement="bottom" title="Leave">
                <Button
                  className={css(getBtnColor('red'), commonSx.square, commonSx.ml10)}
                  onClick={() => {
                    if (getUnknownNumberOnCall()) {
                      endConference(unknownNumberRef?.current, currentParticipants);
                    } else {
                      endConference(null, currentParticipants);
                    }
                  }}
                >
                  <HangupIcon color="white" />
                </Button>
              </Tooltip>
            )}
            {twilioConnection && (
              <Tooltip placement="bottom" title="Add translation service to call">
                <Button
                  type="primary"
                  className={css(commonSx.square, commonSx.ml10)}
                  onClick={async () => {
                    // add participant to conference
                    const activeConf = _get(getActiveConfData, 'getActiveConference.data');

                    const r = await addParticipant({
                      variables: {
                        conferenceSid: activeConf?.conferenceSid,
                        participantType: 'translation_service',
                      },
                    });

                    const callSids = _get(r, 'data.addParticipant');

                    setOnCallDialingParticipant({
                      names: ['Translation Service'],
                      participantType: ['translation_service'],
                      callSids,
                    });
                  }}
                >
                  <AddUserIcon color="white" />
                </Button>
              </Tooltip>
            )}
            <Modal
              open={isConfirmTransferModalOpen}
              cancelText="No"
              okText="Yes"
              onCancel={() => setIsConfirmTransferModalOpen(false)}
              confirmLoading={isTransferring}
              onOk={async () => {
                if (currentEnrollerBeingAdded?.userId) {
                  // if the call was transferred, update the call record to new enroller
                  setIsTransferring(true);
                  const callRecord = await getCallRecordQuery(apolloClient, parseInt(callId, 10));
                  const callDirection = _get(callRecord, 'direction');
                  const updateVariables = {
                    call_id: callId,
                  };

                  if (callDirection === 'inbound') {
                    updateVariables.to_user_id = currentEnrollerBeingAdded.userId;
                  } else {
                    updateVariables.from_user_id = currentEnrollerBeingAdded.userId;
                  }

                  updateCallRecordAfterTransfer({
                    variables: updateVariables,
                  });
                }
              }}
              getContainer={false}
            >
              <Title className="modelHeaderText" level={3}>
                Confirm Transfer
              </Title>
              <div className={css(sx.pb50)}>
                Do you want to transfer this call to{' '}
                {currentEnrollerBeingAdded?.name
                  ? currentEnrollerBeingAdded?.name[0]
                  : 'the added PBS Agent'}
                ?
              </div>
            </Modal>
          </div>
        </div>
      </>
    );
  }

  return <>{renderOnCall()}</>;
}

OnCallContainer.propTypes = {
  callId: PropTypes.number,
  isCallIdSet: PropTypes.bool,
  refreshActiveConfRef: PropTypes.any,
  refreshActiveConf: PropTypes.bool,
  onClickUnknownNumber: PropTypes.func,
  onSwitchPatient: PropTypes.func,
  onCallDialingParticipant: PropTypes.object,
  onCallUpdatingParticipants: PropTypes.func,
  endConference: PropTypes.func,
  setOnCallDialingParticipant: PropTypes.func,
  twilioConnection: PropTypes.any,
  selectedPatientData: PropTypes.any,
  location: PropTypes.any,
  isSaveCallEnabled: PropTypes.bool,
};

export default OnCallContainer;