import React from 'react';
import { Route, Routes, HashRouter as Router, Outlet , Navigate } from 'react-router-dom';
import CallCenterBar from 'ui/CallCenter/CallCenterBar';
import { CallCenterContext } from 'contexts/CallCenterContextProvider';
import { SessionContext } from 'contexts/SessionContextProvider';
import Homepage from 'homepage/homepage';
import PatientsPage from '../patients/PatientsPage';
import * as models from '../contexts/utils/models';
import EmailVerificationPageContainer from '../login/EmailVerificationPageContainer';
import LoginPage from '../login/LoginPage';
import PinLoginPageContainer from '../login/PinLoginPageContainer';
import ForgotPasswordPage from '../password/ForgotPasswordPage';
import ResetPasswordPage from '../password/ResetPasswordPage';
import CreateWorkstationPhonePageContainer from '../register/CreateWorkstationPhonePageContainer';
import RegisterPage from '../register/RegisterPage';
import MyAccountPage from '../settings/my-account/MyAccountPage';
import HelpPage from '../settings/help/HelpPage';
import PhonesPage from '../settings/phones/PhonesPage';
import TeamPage from '../settings/team/TeamPage';
import AuthenticatedRoute from './AuthenticatedRoute';
import VoicemailContainer from '../settings/voicemail/VoicemailPage';
import SupervisorContainer from '../settings/supervisor/SupervisorPage';
import IdleTimerContainer from '../session-trackers/IdleTimerContainer';
import AuthenticatedComponent from './AuthenticatedComponent';
import TopBarContainer from '../ui/topbar/TopBarContainer';
import ChurnMemberList from '../dashboard/components/ChurnMemberList/ChurnMemberList';
import LeadList from '../dashboard/components/LeadList/LeadList';
import MembersList from '../dashboard/components/MemberList/MembersPage';
import PharmaciesPage from '../settings/pharmacies/PharmaciesPage';
import EnrollersListPage from '../settings/EnrollersList/EnrollersListPage';
import NotesPage from '../settings/notes/NotesPage';
import PharmacyNetworksPage from '../settings/pharmacy-networks/PharmacyNetworksPage';
import CampaignPage from '../settings/campaigns/CampaignPage';
import PharmacyTabsPage from '../dashboard/components/PharmacyList/components/PharmacyInfoModal/PharmacyTabsPage';
import ZipCodeAssignmentPage from '../settings/zipcode-assignment/ZipCodeAssignmentPage';
import SiteDownPage from '../login/SiteDownPage';
import useFeatureFlags from '../hooks/useFeatureFlags';
import FeatureFlags from '../constants/featureFlags';
import ModalConductorContainer from '../ui/ModalConductorContainer';
import ContextProvider from 'contexts';
import _get from 'lodash/get';

function Layout() {
  return (
    <>
      <CallCenterContext.Consumer>
      {CallCenterContextProps => {
              if (_get(CallCenterContextProps, 'callCenterUserStatusContext')) {
                return (
                  <>
                  <TopBarContainer />
                  <CallCenterBar />
                  <IdleTimerContainer  callCenterStatus={_get(CallCenterContextProps, 'callCenterUserStatusContext')} />
                  <Outlet />
                  </>
                );
              }

              return (
                <>
                  <TopBarContainer />
                  <Outlet />
                </>
              );
            }}


      </CallCenterContext.Consumer>
      <SessionContext.Consumer>
      {({ state: { currentUser } }) => {
              if (currentUser?.isPbsTeam || currentUser?.isSystemAdmin) {
                return null;
              }
              return null;
            }}
      </SessionContext.Consumer>
      
    </>
  );
}
const RoutesComponent = () => {
  const featureFlags = useFeatureFlags();

  return (
    <Router basename='/'>
    <Routes>
      	<Route path="/" element={<Navigate to="/signin" />} />
        <Route element={<Layout />}>
          <Route path="home" element={<AuthenticatedRoute element={<Homepage />} />} />
          <Route path="settings/team" element = {<AuthenticatedRoute element={<TeamPage />} />} />
          <Route path="settings/account" element = {<AuthenticatedRoute element={<MyAccountPage />} />} />
          <Route path="settings/help" element={<HelpPage />} />
          <Route
            path="settings/voicemail"
            element={
              <AuthenticatedRoute
                allowedRoles={[models.Role.SystemAdmin, models.Role.PbsAdmin, models.Role.PbsLead]}
                allowedFeatureFlags={{ callCenter: true }}
                element={<VoicemailContainer />}
              />
            }
          />
          <Route
            path="settings/supervisor"
            element={
              <AuthenticatedRoute
                allowedRoles={[models.Role.SystemAdmin, models.Role.PbsAdmin, models.Role.PbsLead]}
                allowedFeatureFlags={{ callCenter: true }}
                element={<SupervisorContainer />}
              />
            }
          />
          <Route
            path="settings/phones"
            element={
              <AuthenticatedRoute
                allowedRoles={[models.Role.SystemAdmin, ...models.PBSTeamRoles]}
                allowedFeatureFlags={{ callCenter: false }}
                element={<PhonesPage />}
              />
            }
          />
          <Route
            path="leadlist"
            element={
              <AuthenticatedRoute
                allowedRoles={[models.Role.SystemAdmin, ...models.PBSTeamRoles]}
                element={<LeadList />}
              />
            }
          />
          {!featureFlags[FeatureFlags.CHURNED] && (
            <Route
              path="settings/churnlist"
              element={
                <AuthenticatedRoute
                  allowedRoles={[models.Role.SystemAdmin, ...models.PBSTeamRoles]}
                  element={<ChurnMemberList />}
                />
              }
            />
          )}
          <Route
            path="settings/pharmacylist/modify"
            element={
              <AuthenticatedRoute
                allowedRoles={[models.Role.SystemAdmin,  ...models.PBSTeamRoles,...models.PharmacyTeamRoles]}
                element={<PharmacyTabsPage />}
              />
            }
          />
          <Route
            path="settings/pharmacylist"
            element={
              <AuthenticatedRoute
                allowedRoles={[
                  models.Role.SystemAdmin,
                  ...models.PBSTeamRoles,
                  ...models.PharmacyTeamRoles,
                ]}
                element={<PharmaciesPage />}
              />
            }
          />
          <Route
            path="settings/pharmacynetworkslist"
            element={
              <AuthenticatedRoute
                allowedRoles={[
                  models.Role.SystemAdmin,
                  models.Role.PharmacyBusinessDev,
                  models.Role.PharmacyBusinessDevAdmin,
                  models.Role.ProjectManager,
                ]}
                element={<PharmacyNetworksPage />}
              />
            }
          />
          <Route
            path="settings/zipcodeassignment"
            element={
              <AuthenticatedRoute
                allowedRoles={[models.Role.SystemAdmin, ...models.PharmacyTeamRoles]}
                element={<ZipCodeAssignmentPage />}
              />
            }
          />
          <Route
            path="settings/noteslist"
            element={
              <AuthenticatedRoute
                allowedRoles={[models.Role.SystemAdmin, ...models.PharmacyTeamRoles]}
                element={<NotesPage />}
              />
            }
          />
          <Route
            path="settings/campaigns"
            element={
              <AuthenticatedRoute
                allowedRoles={[
                  models.Role.SystemAdmin,
                  models.Role.PharmacyBusinessDevAdmin,
                  models.Role.PbsAdmin,
                  models.Role.PbsLead,
                  models.Role.PharmacyAscAdmin,
                  models.Role.ProjectManager,
                ]}
                element={<CampaignPage />}
              />
            }
          />
          <Route
            path="settings/pbslist"
            element={
              <AuthenticatedRoute
                allowedRoles={[models.Role.SystemAdmin, ...models.PBSTeamRoles]}
                element={<EnrollersListPage />}
              />
            }
          />
          <Route path="settings/noteslist" element={<NotesPage />} />
          <Route
            path="members"
            element={
              <AuthenticatedRoute
                allowedRoles={[
                  models.Role.SystemAdmin,
                  ...models.PBSTeamRoles,
                  ...models.PharmacyTeamRoles,
                  ...models.PharmacistRoles,
                ]}
                element={<PatientsPage />}
              />
            }
          />
          <Route
            path="settings/memberlist"
            element={
              <AuthenticatedRoute
                allowedRoles={[
                  models.Role.SystemAdmin,
                  ...models.PharmacistRoles,
                  ...models.AscRoles,
                ]}
                element={<MembersList />}
              />
            }
          />
          <Route
            path="create-phone"
            element={
              <AuthenticatedRoute
                allowedRoles={[
                  models.Role.SystemAdmin,
                  ...models.PBSTeamRoles,
                  ...models.PharmacyTeamRoles,
                ]}
                element={<CreateWorkstationPhonePageContainer />}
              />
            }
          />
        </Route>
        <Route path="/signin" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/pin-authentication" element={<PinLoginPageContainer />} />
          <Route path="/verify-email" element={<EmailVerificationPageContainer />} />
          <Route path="/site-down" element={<SiteDownPage />} />  
        <Route path="*" element={<AuthenticatedRoute />} />
      </Routes>
      <ModalConductorContainer />
    </Router>
  );
};

export default RoutesComponent;
