import React, { useRef, useState } from 'react';
import moment from 'moment';
import { compose } from 'recompose';
import { useLazyQuery } from '@apollo/react-hooks';
import { CloseOutlined, DownOutlined, SearchOutlined, UpOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Dropdown, Space, Tooltip, message } from 'antd';
import { StyleSheet, css } from 'aphrodite';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import SEARCH_MEMBERS from 'graphql/searchMembers';
import withSelectPatient from 'hoc/withSelectPatient';
import withSelectedPatientId from 'hoc/withSelectedPatientId';
import formatPhoneNumber from '../../dashboard/components/PharmacyList/utils/FormatPhone.utils';
import './memberSearch.css';

// CSS styles
const sx = StyleSheet.create({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: '400px',
    maxWidth: '500px',
    height: '35px',
    marginRight: '12px',
    border: '1px solid #979797',
    borderRadius: '8px',
  },
  errorContainer: {
    border: '1px solid red',
  },
  errorInput: {
    color: 'red',
  },
  flexItem1: {
    flexBasis: '90%',
    fontSize: '14px',
  },
  flexItem2: {
    flexBasis: '10%',
    flexShrink: '0',
    padding: '10px',
    fontSize: '16px',
    border: 'none',
    background: 'none',
  },
  fontSmall: {
    fontSize: '12px',
  },
  fontMedium: {
    fontSize: '16px',
  },
  dropDownContainer: {
    border: 'none',
    background: 'none',
    color: '#231E33',
    paddingRight: '0px',
  },
  resultsButton: {
    border: 'none',
    background: 'none',
    color: '#231E33',
    boxShadow: 'none',
  },
  dropdownIcon: {
    fontSize: '10px',
  },
  menuNoError: {
    color: 'rgba(0, 0, 0, 0.85)',
    fontSize: '13px',
  },
  menuError: {
    color: 'red',
    fontSize: '13px',
  },
});

const { Option } = AutoComplete;

const categoryLabels = {
  name: 'Name',
  first_name: 'First Name',
  last_name: 'Last Name',
  member_id: 'Member ID',
  anthem_mcid: 'Anthem MCID',
  phone_number: 'Phone Number',
  dob: 'Date of Birth',
};

const categoryRegexValidation = {
  name: {
    regex: /[A-Za-z]+\s[A-Za-z]+/,
    message: 'Search term must be first name and last name',
  },
  first_name: {
    regex: /^[a-zA-Z]+$/,
    message: 'Search term must be letters only',
    altMessage: 'Search by first name only',
  },
  last_name: {
    regex: /^[a-zA-Z]+$/,
    message: 'Search term must be letters only',
    altMessage: 'Search by last name only',
  },
  member_id: {
    regex: /^[A-Za-z0-9]+$/,
    message: 'Search term cannot contain special characters',
  },
  anthem_mcid: {
    regex: /^[A-Za-z0-9]+$/,
    message: 'Search term cannot contain special characters',
  },
  phone_number: {
    regex: /^\d+$/,
    message: 'Phone numbers must be digits only',
  },
  dob: {
    regex: /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
    message: 'DOB must be in the format: mm/dd/yyyy',
  },
};

const defaultMenu = [
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'First Name',
    key: 'first_name',
  },
  {
    label: 'Last Name',
    key: 'last_name',
  },
  {
    label: 'Member ID',
    key: 'member_id',
  },
  {
    label: 'Anthem MCID',
    key: 'anthem_mcid',
  },
  {
    label: 'Phone Number',
    key: 'phone_number',
  },
  {
    label: 'Date of Birth',
    key: 'dob',
  },
];

const MemberSearch = ({ location, navigate, onSelectPatient, selectedPatientId }) => {
  const [search, setSearch] = useState('');
  const [members, setMembers] = useState([]);
  const autocompleteRef = useRef(null);
  const [searchCategory, setSearchCategory] = useState('name');
  const [searchCategories, setSearchCategories] = useState(defaultMenu);
  const [forceDropdownOpen, setForceDropdownOpen] = useState(false);
  const [dropdownDisabled, setDropdownDisabled] = useState(true);
  const [validationError, setValidationError] = useState(null);

  const setCategoryDropdown = value => {
    const newCategories = defaultMenu.filter(c => c.key !== value.key);
    setSearchCategories(newCategories);
    setSearchCategory(value.key);
  };

  const menuProps = {
    items: searchCategories,
    onClick: setCategoryDropdown,
    selectable: false,
  };

  const [getMembersAutocomplete, { loading: isMembersAutoCompleteFetching }] = useLazyQuery(
    SEARCH_MEMBERS,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ searchMembers }) => {
        if (searchMembers) {
          setMembers(searchMembers?.data);
          setValidationError(null);
        }
        setDropdownDisabled(false);
        setForceDropdownOpen(true);
      },
      onError: error => {
        message.error(`Error fetching members: ${error.message}`);
        setDropdownDisabled(true);
      },
    },
  );

  const sanitizePhoneNumber = value => {
    let sanitizedValue = value;
    // if country code is included, remove it
    if (value[0] === '+' && value?.length >= 12) {
      sanitizedValue = value.slice(2, value?.length + 1);
    }

    // remove extra phone number characters
    sanitizedValue = sanitizedValue.replace(/\D/g, '');
    return sanitizedValue;
  };

  const validateSearch = (category, value) => {
    if (category === 'dob') {
      // validate against invalid feb month
      const valueStr = value[0] === '0' ? value.substring(0, 4) : value.substring(0, 3);

      if (['02/3', '2/3'].includes(valueStr)) {
        return false;
      }
    }
    return categoryRegexValidation[searchCategory].regex.test(value);
  };

  const debouncedFetchOptions = value => {
    // sanitize phone number
    if (searchCategory === 'phone_number') {
      value = sanitizePhoneNumber(value);
      setSearch(value);
    }
    // validate search term
    if (!validateSearch(searchCategory, value)) {
      setMembers([]);
      if (
        (searchCategory === 'first_name' || searchCategory === 'last_name') &&
        categoryRegexValidation.name.regex.test(value)
      ) {
        setValidationError(categoryRegexValidation[searchCategory].altMessage);
      } else {
        setValidationError(categoryRegexValidation[searchCategory].message);
      }
      setForceDropdownOpen(true);
      return;
    }
    setValidationError(null);
    const variables = {
      skip: 0,
      limit: 25,
      query: { search: value },
      searchCategory,
    };
    getMembersAutocomplete({ variables });
  };

  const onSearch = () => {
    autocompleteRef.current.focus();
    debouncedFetchOptions(search);
  };

  const changeSearchTerm = value => {
    setValidationError(null);
    setMembers([]);
    setSearch(value);
  };

  // If user not on member page when selecting member, redirect before showing the member info
  const onSelect = (value, option) => {
    if (!option) return;

     const onMembersPage = location?.pathname?.match('members');
    if (!onMembersPage) {
      navigate('/members', { replace: true });
    }

    const newSelectedPatientId = parseInt(option.key, 10);
    if (selectedPatientId !== newSelectedPatientId) {
      onSelectPatient(newSelectedPatientId);
    }
  };

  const submitSearchOnEnter = event => {
    if (event?.key === 'Enter') {
      debouncedFetchOptions(search);
    }
  };

  const clearSearch = () => {
    setSearch('');
    setValidationError(null);
  };

  return (
    <div
      className={`customInput ${css(sx.container)} ${
        validationError ? css(sx.errorContainer) : ''
      }`}
    >
      <Dropdown menu={menuProps} className={css(sx.dropDownContainer)}>
        <Button>
          <Space>{categoryLabels[searchCategory]}|</Space>
        </Button>
      </Dropdown>
      <AutoComplete
        bordered={false}
        className={`${css(sx.flexItem1)} ${validationError ? css(sx.errorInput) : ''}`}
        placeholder="Quick Search Members"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: '300px', zIndex: '900' }}
        value={search}
        defaultActiveFirstOption={false}
        onSearch={value => changeSearchTerm(value)}
        loading={isMembersAutoCompleteFetching}
        onSelect={(value, option) => onSelect(value, option)}
        onChange={() => setForceDropdownOpen(false)}
        ref={autocompleteRef}
        onKeyDown={e => submitSearchOnEnter(e)}
        open={forceDropdownOpen}
        notFoundContent={
          validationError ? (
            <div className={css(sx.menuError)}>{validationError}</div>
          ) : (
            <div className={css(sx.menuNoError)}>No members found</div>
          )
        }
      >
        {members.map((option, index) => {
          const {
            first_name,
            last_name,
            birthday,
            member_ids,
            phone_numbers,
            id,
            anthem_mcid,
          } = option;
          const birthdayMoment = moment.utc(birthday);

          return (
            <Option key={option.id} index={index} value={id}>
              <>
                <div className={css(sx.fontMedium)}>{`${first_name || ''} ${last_name || ''}`}</div>
                <div className={css(sx.fontSmall)}>
                  {`DOB: ${birthday ? birthdayMoment.format('MM/DD/YYYY') : '-'}`}
                </div>
                <div className={css(sx.fontSmall)}> {`Member ID: ${member_ids || '-'}`}</div>
                <div className={css(sx.fontSmall)}> {`Anthem MCID: ${anthem_mcid || '-'}`}</div>
                <div className={css(sx.fontSmall)}>
                  {`Phone Number: ${phone_numbers ? formatPhoneNumber(phone_numbers) : '-'}`}
                </div>
              </>
            </Option>
          );
        })}
      </AutoComplete>
      <Tooltip title={`${forceDropdownOpen ? 'Close' : 'Open'} results`}>
        <Button
          shape="circle"
          size="small"
          className={css(sx.resultsButton)}
          hidden={dropdownDisabled}
          icon={
            forceDropdownOpen ? (
              <UpOutlined className={css(sx.dropdownIcon)} />
            ) : (
              <DownOutlined className={css(sx.dropdownIcon)} />
            )
          }
          onClick={() => setForceDropdownOpen(!forceDropdownOpen)}
        />
      </Tooltip>
      {validationError ? (
        <Tooltip title="Clear search">
          <Button
            shape="circle"
            className={css([sx.flexItem2, sx.menuError])}
            icon={<CloseOutlined />}
            onClick={() => clearSearch()}
          />
        </Tooltip>
      ) : (
        <Tooltip title="Search for members">
          <Button
            shape="circle"
            className={css(sx.flexItem2)}
            icon={<SearchOutlined />}
            onClick={() => onSearch(search)}
          />
        </Tooltip>
      )}
    </div>
  );
};

MemberSearch.propTypes = {
  location: PropTypes.any,
  navigate: PropTypes.func.isRequired,
  onSelectPatient: PropTypes.func.isRequired,
  selectedPatientId: PropTypes.number,
};

const MemberSearchWithNavigate = props => {
  const navigate = useNavigate();
  return <MemberSearch {...props} navigate={navigate} />;
};

export default compose(withSelectPatient, withSelectedPatientId)(MemberSearchWithNavigate);
