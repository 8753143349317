import { compose, withHandlers } from 'recompose';
import withCurrentPharmacy from '../../hoc/withCurrentPharmacy';
import withCurrentUser from '../../hoc/withCurrentUser';
import withWorkstationPhone from '../../hoc/withWorkstationPhone';
import TopBar from './TopBar';
import { useNavigate } from 'react-router-dom';
import React from 'react';

const withNavigationHandlers = (Component) => (props) => {
  const navigate = useNavigate();

  const handlers = {
    onHomeClick: () => navigate('/home'),
    onOutcomesClick: () => navigate('/outcomes'),
    onPhoneClick: () => navigate('/settings/phones'),
  };

  return <Component {...props} {...handlers} />;
};

const EnhancedTopBar = compose(
  withWorkstationPhone,
  withCurrentUser,
  withCurrentPharmacy,
  withNavigationHandlers,
)(TopBar);

const TopBarContainer = (props) => {
  return (
    <EnhancedTopBar {...props} />
  );
};

export default TopBarContainer;