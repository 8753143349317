import '@zipdrug/ui/lib/ui.css';
import 'react-virtualized/styles.css'; // required by react-virtualized
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
// import { HashRouter } from "react-router-dom";
const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
    <App />
    

);