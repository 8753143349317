import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { WorkstationPhoneContext } from 'contexts/WorkstationPhoneContextProvider';
import LoadingPage from '../ui/LoadingPage';
import { SessionContext, ActionTypes } from '../contexts/SessionContextProvider';
import { queryCurrentUser } from '../graphql/users';
import { queryPhones } from '../graphql/phones';
import fetchOnceOptions from '../hoc/utils/fetchOnceOptions';
import { isAuthenticated } from './AuthenticatedComponent';

const AuthenticatedRoute = ({ element, allowedRoles, allowedFeatureFlags, ...rest }) => {
  const { userId, state, dispatch, ready } = useContext(SessionContext);
  const { workstationPhoneId } = useContext(WorkstationPhoneContext);
  const { currentUser } = state;
  const hasRole = get(currentUser, 'hasRole');

  const { loading: userLoading, data: userData } = useQuery(queryCurrentUser, {
    ...fetchOnceOptions(`currentUser_${userId}`, { skip: !userId }, true),
    notifyOnNetworkStatusChange: true,
    onError(e) {
      if (e) {
        dispatch({
          type: ActionTypes.ERROR,
          payload: e,
        });
      }
    },
    onCompleted(data) {
      if (data) {
        dispatch({
          type: ActionTypes.SET_CURRENT_USER,
          payload: data,
        });
      }
    },
  });

  const { loading: phonesLoading } = useQuery(queryPhones, {
    ...fetchOnceOptions('phones', {
      skip: !userId || !userData || !workstationPhoneId,
      variables: {
        query: {
          is_zipdrug: !hasRole?.pharmacistUser || !hasRole?.pharmacistAdmin,
          id: workstationPhoneId,
        },
      },
    }),
    notifyOnNetworkStatusChange: true,
  });

  const loading = phonesLoading || userLoading;

  const hasAllowedRole = () => {
    if (currentUser) {
      const userRoles = currentUser?.roles?.map(role => role.type);
      const userFlags = currentUser?.feature_flags;
      const isAllowed =
        (allowedRoles ? allowedRoles.some(allowedRole => userRoles.includes(allowedRole)) : true) &&
        (allowedFeatureFlags
          ? Object.keys(allowedFeatureFlags).every(flag =>
              allowedFeatureFlags[flag]
                ? allowedFeatureFlags[flag] === userFlags?.[flag]
                : userFlags?.[flag] !== true,
            )
          : true);
      return isAllowed;
    }
    return true;
  };

  if (loading || !ready) {
    return <LoadingPage />;
  }

  if (!isAuthenticated(userId)) {
    return <Navigate to="/signin" />;
  }

  if (!hasAllowedRole()) {
    return <Navigate to="/members" />;
  }

  return React.cloneElement(element, { ...rest });
};

AuthenticatedRoute.propTypes = {
  element: PropTypes.element.isRequired,
  allowedRoles: PropTypes.array,
  allowedFeatureFlags: PropTypes.object,
};

export default AuthenticatedRoute;
