import React, { Component, Fragment } from 'react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import { useNavigate } from 'react-router-dom';
import AutoLogoutWarning from './AutoLogoutWarning';
import { INACTIVITY_TIMEOUT_IN_SECONDS, INACTIVITY_WARNING_TIMEOUT_IN_SECONDS } from '../settings';

const PARSED_INACTIVITY_TIMEOUT = parseInt(INACTIVITY_TIMEOUT_IN_SECONDS, 10) || 900;
const PARSED_INACTIVITY_WARNING_TIMEOUT =
  parseInt(INACTIVITY_WARNING_TIMEOUT_IN_SECONDS, 10) || 300;
export const LAST_USER_ACTIVITY_COOKIE = 'lastUserActivity';

function getElapsedTimeSinceLastActivity() {
  return Date.now() - parseInt(Cookies.get(LAST_USER_ACTIVITY_COOKIE), 10);
}

class IdleTimerContainer extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    // The elapsed time in milliseconds when to show the warning.
    this.showWarningTimeout =
      1000 * (PARSED_INACTIVITY_TIMEOUT - PARSED_INACTIVITY_WARNING_TIMEOUT);
    this.state = {
      showModal: false,
      remaining: null,
    };

    this.warningTimeoutCb = null;
  }

  componentDidMount() {
    const { callCenterStatus } = this.props;

    // We don't want to logout if the user is on a call. We also check if callCenterStatus has been initiated. There's a case
    // when callCenterStatus hasn't been initiated yet.
    if (
      callCenterStatus &&
      callCenterStatus !== 'oncall' &&
      Cookies.get(LAST_USER_ACTIVITY_COOKIE)
    ) {
      // Check the elapsed time from when they close the tab to now and determine if it's more than the INACTIVITY_TIMEOUT threshold.
      const elapsedTime = Date.now() - parseInt(Cookies.get(LAST_USER_ACTIVITY_COOKIE), 10);

      if (elapsedTime > PARSED_INACTIVITY_TIMEOUT * 1000) {
        this.handleLogout();
      }
    }

    if (callCenterStatus === 'oncall' && this.idleTimer) {
      this.idleTimer.pause();
    } else {
      Cookies.set(LAST_USER_ACTIVITY_COOKIE, Date.now());
      this.idleTimer.resume();
      this.idleTimer.reset();
    }
  }

  componentDidUpdate(prevProps) {
    const { callCenterStatus } = this.props;
    const { callCenterStatus: prevCallCenterStatus } = prevProps;

    if (prevCallCenterStatus !== callCenterStatus) {
      if (callCenterStatus === 'oncall' && this.idleTimer) {
        this.idleTimer.pause();
      } else {
        Cookies.set(LAST_USER_ACTIVITY_COOKIE, Date.now());
        this.idleTimer.resume();
        this.idleTimer.reset();
      }
    }
  }

  componentWillUnmount() {
    if (this.warningTimeoutCb) {
      clearTimeout(this.warningTimeoutCb);
    }
  }

  /**
   * This fn will trigger when it reaches the warning timeout. We also need to check that there's no activity
   * in other tabs by checking the lastUserActivity in the cookie. If we determine that there's no activity across all
   * tabs then we show the inactivity warning modal.
   */
  onIdle = () => {
    this.idleTimer.reset();

    // Check if there are no activity in other tabs.
    if (!this.state.showModal && this.showInactivityWarningModal()) {
      this.setState({
        showModal: true,
        remaining: PARSED_INACTIVITY_WARNING_TIMEOUT * 1000,
      });

      this.warningTimeoutCb = setTimeout(() => {
        if (
          this.state.showModal &&
          getElapsedTimeSinceLastActivity() > PARSED_INACTIVITY_TIMEOUT * 1000
        ) {
          this.handleLogout();
        } else {
          this.setState({
            showModal: false,
          });
        }
      }, PARSED_INACTIVITY_WARNING_TIMEOUT * 1000);
    }
  };

  onAction = () => {
    if (getElapsedTimeSinceLastActivity() > PARSED_INACTIVITY_TIMEOUT * 1000) {
      this.handleLogout();

      return;
    }

    // We set this cookie to keep track of any activities across all tabs.
    Cookies.set(LAST_USER_ACTIVITY_COOKIE, Date.now());
    clearTimeout(this.warningTimeoutCb);
    this.setState({ showModal: false });
  };

  showInactivityWarningModal() {
    return getElapsedTimeSinceLastActivity() > this.showWarningTimeout;
  }

  handleLogout = () => {
    this.setState({ showModal: false });
    this.props.navigate('/signin');
  };

  dismissWarning = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    return (
      <Fragment>
        <IdleTimer
          ref={ref => {
            this.idleTimer = ref;
          }}
          throttle={1000}
          onIdle={this.onIdle}
          onAction={this.onAction}
          timeout={this.showWarningTimeout}
          events={['click', 'keydown']}
        />
        {this.state.showModal && (
          <AutoLogoutWarning onDismiss={this.dismissWarning} remainingTime={this.state.remaining} />
        )}
        {this.props.children}
      </Fragment>
    );
  }
}

IdleTimerContainer.propTypes = {
  navigate: PropTypes.func,
  children: PropTypes.object,
  callCenterStatus: PropTypes.string,
};

const IdleTimerContainerWithNavigate = (props) => {
  const navigate = useNavigate();
  return <IdleTimerContainer {...props} navigate={navigate} />;
};

export default IdleTimerContainerWithNavigate;