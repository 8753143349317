import {
  colors,
  Column,
  Div,
  DropdownFooter,
  FlexItem,
  font,
  formatAddress,
  LinkButton,
  padding,
  Row,
  space,
  TruncateText,
} from '@zipdrug/ui';
import React, { Fragment } from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Tag, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import CallButtonContainer from '../../../ui/CallButtonContainer';
import NewTag from '../../../ui/NewTag/NewTag';
import withRoles from '../../../hoc/withRoles';

const sx = StyleSheet.create({
  container: {
    ...padding.build(space.half, space.half, font.size.small),
    color: colors.gray62,
    fontSize: font.size.xSmall,
    lineHeight: font.lineHeight.looser,
    overflow: 'hidden',
    width: '29rem',
  },
  content: {
    paddingRight: space.half,
    maxWidth: '22.5rem',
  },
  title: {
    color: colors.gray20,
    fontSize: font.size.small,
    lineHeight: font.lineHeight.tight,
    marginBottom: '0.6rem',
    width: '100%',
  },
  linkText: {
    color: '#3A3BC9',
    cursor: 'pointer',
  },
  hubPharmacyTag: {
    backgroundColor: '#F7F7F7',
    border: '1px solid #D9D9D9 !important',
    color: '#231E33 !important',
    fontSize: '12px',
    textAlign: 'center',
    margin: '5px 5px 3px 0px',
    padding: '0px 5px',
  },
});

const { Text } = Typography;

const PharmacyMenuAddressSection = ({ address }) => {
  if (!address || !Object.keys(address).length) return <Div>No address data</Div>;
  const { city, line1, line2, postalCode, state } = address;

  return (
    <Fragment>
      <Div>{formatAddress({ line1, line2 })}</Div>
      <Div>{formatAddress({ city, state, postalCode })}</Div>
    </Fragment>
  );
};

const NewTagSelection = ({ name, openPharmacyProfile, isPbsTeam, isSystemAdmin }) => {
  return (
    <>
      {(isPbsTeam || isSystemAdmin) && name !== 'Pharmacy unavailable' ? (
        <Space>
          <Text
            style={{ fontSize: '13px' }}
            className={css(sx.linkText)}
            type="link"
            underline
            onClick={openPharmacyProfile}
          >
            {name}
          </Text>
        </Space>
      ) : (
        <TruncateText styles={sx.title}>{name}</TruncateText>
      )}
    </>
  );
};

PharmacyMenuAddressSection.propTypes = {
  address: PropTypes.object,
};

NewTagSelection.propTypes = {
  name: PropTypes.string,
  openPharmacyProfile: PropTypes.func,
  isPbsTeam: PropTypes.bool,
  isSystemAdmin: PropTypes.bool,
};

const PharmacyMenu = ({ onToggleEdit, pharmacy, isSystemAdmin, isPbsTeam }) => {
  const navigate = useNavigate();
  const name = get(pharmacy, 'name', 'Pharmacy unavailable');
  const phones = get(pharmacy, 'phones');
  let phone;
  if (phones) {
    phone = phones.some(item => item.label === 'Main Line')
      ? phones.find(element => element.label === 'Main Line')
      : phones[0];
  }
  const skipNumber = get(pharmacy, 'skip_number');
  const openPharmacyProfile = () => {
    navigate({
      pathname: `/settings/pharmacylist/modify`,
      search: `${pharmacy?.id}`,
      state: {
        isEdit: true,
      },
    });
  };

  return (
    <Column>
      <Row align="start" justify="space-between" styles={sx.container}>
        <Column styles={sx.content}>
          <NewTagSelection
            name={name.includes('(Hub)') ? name.replace('(Hub)', '') : name}
            openPharmacyProfile={openPharmacyProfile}
            isPbsTeam={isPbsTeam}
            isSystemAdmin={isSystemAdmin}
          />
          <div>
            {name.includes('(Hub)') ? (
              <Tag className={css(sx.hubPharmacyTag)}>Hub Pharmacy</Tag>
            ) : (
              ''
            )}
            <NewTag
              created_at={get(pharmacy, 'created_at')}
              toggleToolTip={false}
              style={css(sx.hubPharmacyTag)}
            />
          </div>
          <PharmacyMenuAddressSection address={get(pharmacy, 'address')} />
        </Column>
        {phone && (
          <FlexItem flex="none">
            <CallButtonContainer
              isActivePharmacy={pharmacy?.status === 'active'}
              phone={phone}
              title={`Call ${name}`}
            />
          </FlexItem>
        )}
      </Row>
      <Row align="start" justify="space-between" styles={sx.container}>
        {skipNumber && (
          <Column styles={sx.content}>
            <TruncateText styles={sx.title}>Skip Number</TruncateText>
            <Div>
              Press &quot;{skipNumber}&ldquo; after calling pharmacy to be directed to pharmacist
            </Div>
          </Column>
        )}
      </Row>
      <DropdownFooter>
        <LinkButton id="e2e-pharmacyMenuReassignButton" onClick={onToggleEdit}>
          Reassign
        </LinkButton>
      </DropdownFooter>
    </Column>
  );
};

PharmacyMenu.propTypes = {
  onToggleEdit: PropTypes.func,
  pharmacy: PropTypes.object,
  isPbsTeam: PropTypes.bool,
  isSystemAdmin: PropTypes.bool,
};

export default withRoles(PharmacyMenu);